.red-row {
    background-color: rgb(254, 133, 133);
    font-weight: 500;
  }

  .yellow-row {
    background-color: rgb(255, 224, 181);
    font-weight:500;
  }

  .ant-table-cell.sorted.ant-table-column-sort {
    background-color: rgba(255, 255, 255, 0);
  }
  .red-row:hover {
    font-weight:400
  }

  .yellow-row:hover {
    font-weight:400
  }

  .modalStyle .ant-modal-content {
    padding: 0;
    }

  .datepicker .ant-picker-input .ant-picker-suffix {
    color: white;
  }

  .datepicker .ant-picker-input input {
    color: white;
  }

  .ant-carousel .slick-dots li button {
    background: #726565;
    opacity: 0.4;
}
 
.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #000000;
}

.custom-modal .ant-modal-content {
  padding-bottom: 0;
}

.ant-modal .ant-modal-close{
  top: 10px;
}
  .datepicker .ant-picker-input input::placeholder {
    color: white;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

/* Adjust the font size for smaller screens */
@media screen and (max-width: 767px) {
  .modal-title {
    font-size: 16px;
  }
}

/* Adjust the font size for larger screens */
@media screen and (min-width: 1200px) {
  .modal-title {
    font-size: 24px;
  }
}

