/* Table styles */
.table-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .table-border {
    border-collapse: separate;
    border-spacing: 0;
    border-top: 10px solid #4096FF;
}
  
  .ant-table {
    background-color: white;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    font-weight: 600;
  }
  
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f2f5;
  }
  
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #f5f5f5;
  }
  
  /* Modal styles */
  .modalStyle {
    border-radius: 8px;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
  